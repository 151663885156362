import * as React from "react"
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <div>
      <p>An error occurred. But you knew that, didn't you? You did it deliberately.</p>
      <p>I'm watching you.</p>
      <p><Link to="/">Home</Link></p>
      <footer>
        &copy; J Parkin {new Date().getFullYear()}.
      </footer>
  </div>
  )
}

export default NotFoundPage
